<template>
    <div class="my-4">
    <div v-if="selectedNetwork.chain == 'ETH'">
        <img v-if="index == 0" src="@/assets/icons/coins-icons.png" height="148px" alt="">
        <img v-if="index == 1" src="@/assets/icons/sigeth.png" height="148px" alt="">

        <img v-if="index == 2" src="" height="148px" alt="">
        <img v-if="index == 3" src="@/assets/icons/sig.svg" height="148px" alt="">
    </div>
    <div v-if="selectedNetwork.chain == 'BSC'">
        <img v-if="index == 0" src="@/assets/icons/coins-icons-bsc.png" height="148px" alt="">
        <img v-if="index == 1" src="@/assets/icons/sig-bsc.png" height="148px" alt="">
        <img v-if="index == 2" src="@/assets/icons/sig.svg" height="148px" alt="">
    </div>
    </div>
</template>

<script>
import { selectedNetwork } from "@/network";

export default {
    name: "PoolIcon",
    data: () => ({
        selectedNetwork,
    }),
    props: {
        index: { required: true },
    },
}
</script>
