








































































































































































































import { selectedNetwork, CONTRACT } from "@/network";
import { fetchAPY } from "@/init";
import { Component, Vue } from "vue-property-decorator";
import PoolIcon from "@/components/UI/PoolIcon.vue";

@Component({
    name: "Rewards",
    components: {
        PoolIcon,
    },
    data: () => ({
        selectedNetwork,
    }),
})
export default class Rewards extends Vue {
    private APY1: number | null = null
    private APY2: number | null = null
    private APY3: number | null = null

    async mounted () {
        this.APY1 = null
        this.APY2 = null
        this.APY3 = null

        fetchAPY(1).then((val) => this.APY1 = Number(val) || null)
        fetchAPY(2).then((val) => this.APY2 = Number(val) || null)
        fetchAPY(3).then((val) => this.APY3 = Number(val) || null)

    }

    goTo(id: string) {
        this.$router.push({ name: 'StakeWallet', params: { 'poolIndex': id } })
    }

    goToExternal(url: string) {
        window.location.href = url
    }

    get sigTokenAddress () {
        return CONTRACT.sigToken
    }
}
